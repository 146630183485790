import { useState } from 'react';

export const useTooltip = <T extends HTMLElement>() => {
	const [tooltipAnchor, setTooltipAnchor] = useState<T | null>(null);

	const [tooltipOpened, setTooltipOpened] = useState(false);

	const openTooltip = (): void => {
		setTooltipOpened(true);
	};

	const closeTooltip = (): void => {
		setTooltipOpened(false);
	};

	return {
		tooltipAnchor,
		setTooltipAnchor,
		tooltipOpened,
		openTooltip,
		closeTooltip,
	};
};
