import z from 'zod';

const affiliate = z.literal('ROLE_CABINET_AFFILIATE');
const manager = z.literal('ROLE_CABINET_MANAGER');
const marketer = z.literal('ROLE_CABINET_MARKETER');
const marketerVip = z.literal('ROLE_CABINET_MARKETER_VIP');

export const userRolesSchema = z.object({
	affiliate,
	manager,
	marketer,
	marketerVip,
});

export const loginCredentialsSchema = z.object({
	login: z.string(),
	password: z.string(),
});

export const loginResponseTokensSchema = z.object({
	exp: z.number(),
	iat: z.number(),
	login: z.string(),
	needChangePassword: z.boolean(),
	roles: z.array(z.union([affiliate, manager, marketer, marketerVip])),
});

export const loginErrorResponseSchema = z.object({
	message: z.string(),
});
