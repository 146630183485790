import React, { type FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { type Placement } from '@popperjs/core';
import clsx from 'clsx';
import { usePopper } from 'react-popper';

import { useClickAway } from '@shared/hooks/use-click-away';
import { useLatest } from '@shared/hooks/use-latest';

import s from './styles.module.css';

type TTooltipProps = {
	opened?: boolean;
	placement?: Placement;
	distance?: number;
	children: React.ReactNode;
	className?: string;
	anchor: HTMLElement | null;
	container?: HTMLElement;
	onClose?(): void;
};

export const Tooltip: FC<TTooltipProps> = ({
	opened = false,
	placement = 'auto',
	distance = 4,
	children,
	className,
	anchor,
	container,
	onClose,
}) => {
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

	const popperElementRef = useLatest(popperElement);

	const { styles: popperStyles, attributes } = usePopper(anchor, popperElement, {
		placement,
		modifiers: [
			{
				name: 'arrow',
				options: {
					element: arrowElement,
					padding: 10,
				},
			},
			{
				name: 'offset',
				options: {
					offset: [0, distance],
				},
			},
		],
	});

	useClickAway(popperElementRef, (event) => {
		if (!anchor || anchor.contains(event.target as Node)) {
			return;
		}

		onClose?.();
	});

	if (!anchor) {
		return null;
	}

	const tooltip = (
		<CSSTransition
			in={opened}
			timeout={125}
			mountOnEnter
			unmountOnExit
			classNames={{
				enter: s.isEnter,
				enterActive: s.isEnterActive,
				enterDone: s.isEnterDone,
				exit: s.isExit,
				exitActive: s.isExitActive,
			}}
			nodeRef={popperElementRef}
		>
			<div
				ref={setPopperElement}
				className={clsx(s.tooltip, className)}
				style={popperStyles.popper}
				{...attributes.popper}
			>
				{children}

				<div
					ref={setArrowElement}
					className={s.arrow}
					style={popperStyles.arrow}
					{...attributes.arrow}
				/>
			</div>
		</CSSTransition>
	);

	if (container) {
		return createPortal(tooltip, container);
	}

	return tooltip;
};
