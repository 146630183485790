import { styled } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';

import { OmitDollarPropsOptions } from '@shared/utils/mui/omit-dollar-props-options';

export const ReportTableContainer = TableContainer;

export const ReportTablePaper = styled('div')(({ theme }) => ({
	width: '100%',
	overflow: 'hidden',
	background: theme.palette.custom.component.tableRow,
}));

export const ReportTable = styled(
	Table,
	OmitDollarPropsOptions,
)<{ $scrollable?: boolean }>(({ theme, $scrollable = true }) => ({
	...($scrollable
		? {
				tableLayout: 'fixed',
				whiteSpace: 'nowrap',
			}
		: {}),
	'& td, th': {
		...theme.typography.body1,
		border: '0',
		padding: `${theme.typography.pxToRem(14)} ${theme.typography.pxToRem(24)}`,
		minHeight: theme.typography.pxToRem(25),
		borderCollapse: 'separate',
		borderSpacing: 0,
	},
}));

export const ReportTableHead = styled(TableHead)(() => ({
	position: 'sticky',
	top: 0,
	zIndex: 1,
}));

export const ReportTableBody = styled(TableBody)(() => ({
	position: 'relative',
}));

export const ReportTableRowNames = styled(TableRow)(({ theme }) => ({
	'& td, th': {
		color: theme.palette.custom.component.placeholder,
		padding: `${theme.typography.pxToRem(18)} ${theme.typography.pxToRem(24)}`,
	},
}));

export const ReportTableRowTotals = styled(TableRow)(() => ({
	'& td, th': {
		fontWeight: '600',
	},
}));

export const ReportTableEmptyRowData = styled(TableRow)(({ theme }) => ({
	'&:hover th, &:hover th': {
		backgroundColor: 'grey',
	},

	'&:not(:last-child)': {
		borderBottom: `1px solid ${theme.palette.custom.component.tableSectionDivider}`,
	},
}));

export const ReportTableRowData = styled(
	ReportTableEmptyRowData,
	OmitDollarPropsOptions,
)<{
	$isWeekend: boolean;
}>(({ theme, $isWeekend }) => ({
	'& td, th': {
		color: $isWeekend ? theme.palette.custom.component.placeholder : theme.palette.primary.main,
	},
}));

type TCellAlignment = 'left' | 'right' | 'center' | undefined;

const getFlexPropByAlign = (align: TCellAlignment) => {
	switch (align) {
		case 'left':
			return 'flex-start';
		case 'right':
			return 'flex-end';
		default:
			return 'center';
	}
};

export const TableCellContent = styled(
	'div',
	OmitDollarPropsOptions,
)<{ $align: TCellAlignment }>(({ $align }) => ({
	display: 'flex',
	flexFlow: 'row nowrap',
	alignItems: 'center',
	justifyContent: getFlexPropByAlign($align),
}));

export const TableCellContentNames = styled(TableCellContent)(({ theme }) => ({
	userSelect: 'none',

	'& > *:not(:last-child)': {
		marginRight: theme.typography.pxToRem(4),
	},
}));

export const ReportTableCell = styled(TableCell)(({ theme }) => ({
	background: theme.palette.custom.component.tableRow,

	'.MuiTableRow-hover:hover &, &.column-hovered': {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
	},

	'.MuiTableRow-hover:hover &:hover': {
		backgroundColor: 'rgba(9, 9, 50, 0.12)',
	},

	'&.pinned': {
		zIndex: 2,
		backgroundColor: 'white!important',
	},

	'&.last-pinned': {
		borderRight: `${theme.typography.pxToRem(1)} solid ${theme.palette.custom.component.tableSectionDivider}`,
	},
}));

export const MessageOverlay = styled('tr')(({ theme }) => ({
	display: 'flex',
	flexFlow: 'column nowrap',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'absolute',
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	backgroundColor: theme.palette.custom.component.tableRow,
}));

export const MessageOverlayTitle = styled('td')(({ theme }) => ({
	...theme.typography.messageTitle,
	padding: '0!important',
	margin: '0!important',
}));

export const MessageOverlayDescription = styled('td')(({ theme }) => ({
	...theme.typography.messageDescription,
	color: theme.palette.custom.component.placeholder,
	padding: '0!important',
	margin: '0!important',
}));

export const DateYearSpan = styled('span')(({ theme }) => ({
	...theme.typography.messageDescription,
	color: theme.palette.custom.component.placeholder,
}));
