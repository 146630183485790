import { type RefObject, useEffect, useRef } from 'react';

const defaultEvents = ['mousedown', 'touchstart'];

export const useClickAway = <E extends Event = Event>(
	ref: RefObject<HTMLElement | null>,
	onClickAway: (event: E) => void,
	events: string[] = defaultEvents,
): void => {
	const savedCallback = useRef(onClickAway);

	useEffect(() => {
		savedCallback.current = onClickAway;
	}, [onClickAway]);

	useEffect(() => {
		const handler = (event: Event) => {
			const { current: element } = ref;

			if (element && !element.contains(event.target as Node)) {
				savedCallback.current(event as E);
			}
		};

		events.forEach((eventName) => {
			document.addEventListener(eventName, handler);
		});

		return () => {
			events.forEach((eventName) => {
				document.removeEventListener(eventName, handler);
			});
		};
	}, [events, ref]);
};
