import React, { useRef } from 'react';

import TruncatedText from '@shared/components/trancated-text';
import { Tooltip } from '@shared/components/tooltip';
import { useTooltip } from '@shared/components/tooltip/hooks/use-tooltip';

type TTruncatedTextWithTooltipProps = React.PropsWithChildren;

export const TruncatedTextWithTooltip: React.FC<TTruncatedTextWithTooltipProps> = ({
	children,
}) => {
	const fullTextElRef = useRef<HTMLSpanElement | null>(null);

	const { setTooltipAnchor, tooltipOpened, tooltipAnchor, openTooltip, closeTooltip } =
		useTooltip<HTMLSpanElement>();

	const onTextHover = () => {
		if (tooltipAnchor !== null && fullTextElRef.current !== null) {
			/**
			 * Show tooltip only if full-text has more width then parent container
			 */
			if (fullTextElRef.current.offsetWidth > tooltipAnchor.offsetWidth) {
				openTooltip();
			}
		}
	};

	const onTextHoverExit = () => {
		closeTooltip();
	};

	return (
		<>
			<TruncatedText ref={setTooltipAnchor}>
				<span ref={fullTextElRef} onMouseEnter={onTextHover} onMouseLeave={onTextHoverExit}>
					{children}
				</span>
			</TruncatedText>

			<Tooltip
				opened={tooltipOpened}
				anchor={tooltipAnchor}
				distance={12}
				placement="top"
				onClose={closeTooltip}
			>
				{children}
			</Tooltip>
		</>
	);
};
